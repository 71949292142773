import { RedocStandalone } from 'redoc';

function App() {
  return (
    <div>
        <RedocStandalone 
          specUrl={`${process.env.PUBLIC_URL}/myveego.json`}
          onLoaded={error => {
            if (!error) {
              console.log('Yay!');
            } else {
              console.log(error);
            }
          }}
        />
    </div>
  );
}

export default App;
